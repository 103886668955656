<header class="topbar">
  <!-- <p class="claim-depricated">This site has been archived; ongoing changes won't impact live claims, and customer communication has been discontinued here.</p> -->
  <nav class="navbar top-navbar navbar-expand-md navbar-light primary-background">
    <!-- ============================================================== -->
    <!-- Logo -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <div>
        <a
          class="navbar-brand"
          [routerLink]="['/products']"
        >
          <!-- Logo icon -->
          <b>
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <!-- <img src="assets/images/pronto-logo.svg" alt="Pronto" class="dark-logo" style="width: 40px;" /> -->
            <!-- Light Logo icon -->
            <!-- <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo" /> -->
          </b>
          <!--End Logo icon -->
          <!-- Logo text -->
          <span style="font-weight:bold; text-transform:capitalize;">
            <!-- dark Logo text -->
            <img
              src={{logo}}
              alt={{appname}}
              class="dark-logo"
              style="width: 95px;"
            />
            Claims
            <!-- Light Logo text -->
            <!-- <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage" /> -->
          </span>
        </a>
        <!-- <a
          class="navbar-brand"
          *ngIf="selectedCustomer && loggedInUserType !== 'customer'"
        >
         <span style="font-weight:bold; text-transform:uppercase;">
            <img
              src="assets/images/{{applogo}}-logo.png"
              alt="{{appname}}"
              class="dark-logo"
              style="width: 95px;"
            />
            Claims
          </span>
        </a> -->
        <div
          ngbDropdown
          class="d-inline-block ml-3 navbar-nav header-agents-filter-container-bgcolor"
          *ngIf="
            selectedCustomer &&
            selectedCustomer >= 1 &&
            loggedInUserType !== 'customer' &&
            loggedInUserType !== 'adminview'
          "
        >
          <i
            class="icon-options-vertical cursor-pointer"
            id="dropdownManual"
            ngbDropdownToggle
          ></i>
          <div
            ngbDropdownMenu
            aria-labelledby="dropdownManual"
            class="customdrpdown"
          >
            <div class="form-group form-custom">
              <label
                class="d-block mb-0"
                style="border-bottom: 1px solid #eee;"
                *ngIf="loggedInUserType !== 'customer'"
                >Filter By Agent</label
              >
              <select
                class="form-control custom-select mt-2"
                *ngIf="
                  selectedCustomer &&
                  selectedCustomer >= 1 &&
                  router.url !== '/user' &&
                  loggedInUserType !== 'customer'
                "
                (change)="onChangeSwitchAgent($event)"
                (click)="$event.stopPropagation()"
                [value]="selectedCustomer"
              >
                <option value="1">Steve Jobs</option>
                <option value="2">John Doe</option>
                <option value="3">Michel cleark</option>
              </select>
              <label
                class="agent-user-label"
                *ngIf="loggedInUserType === 'customer'"
                >Steve Jobs
                <span *ngIf="loggedInUserType === 'AGENT'">(Agent)</span>
                <span *ngIf="loggedInUserType === 'USER'">(Agency User)</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <button class="btn btn-sm bg-white mobile-menu-icon menutoggler">
        <i class="mdi mdi-menu"></i>
      </button>
    </div>

    <!-- ============================================================== -->
    <!-- End Logo -->
    <!-- ============================================================== -->
    <div class="navbar-collapse logotxt">
      <!-- ============================================================== -->
      <!-- toggle and nav items -->
      <!-- ============================================================== -->
      <ul class="navbar-nav header-menus-container mr-auto mt-md-0 mr-5">
        <li class="nav-item">
          <a
            class="d-none nav-link sidebartoggler text-white waves-effect waves-dark"
            href="javascript:void(0)"
          >
            <i class="ti-menu"></i>
          </a>
        </li>
        <li class="nav-item">
          <a
          *ngIf="loggedInUserType === 'dowcsuperadmin'"
            class="nav-link waves-effect waves-dark"
            tabindex="1"
            routerLink="/support"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="fa fa-comment-o mr-2"></i> <span>Support</span></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="2"
            routerLink="/products"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-grid mr-2"></i> <span>Products</span></a
          >
        </li>
        <li class="nav-item" *ngIf="loggedInUserType !== 'customer' && selectedProduct==='gap'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="3"
            routerLink="/dashboard"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-view-dashboard mr-2"></i> <span>GAP Claim</span></a
          >
        </li>
        <li class="nav-item" *ngIf="(loggedInUserType === 'superadmin' || loggedInUserType === 'dowcadmin' || loggedInUserType === 'dowcsuperadmin') && selectedProduct==='gap'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="4"
            routerLink="/analytics"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="fa fa-dashboard mr-2"></i> <span>Analytics</span></a
          >
        </li>
        <li class="nav-item" *ngIf="(loggedInUserType === 'superadmin' || loggedInUserType === 'dowcsuperadmin') && selectedProduct==='gap'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="5"
            routerLink="/report"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="fa fa-database mr-2"></i> <span>Reports</span></a
          >
        </li>
        <li
        class="nav-item"
        *ngIf="
          (loggedInUserType === 'customer' ||
          (loggedInUserType !== 'customer' && selectedCustomer !== 'null') ) && selectedProduct==='gap'
        "
      >
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="6"
          routerLink="/claim"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <i class="mdi mdi-disqus mr-2"></i> <span>Manage Claims</span></a
        >
      </li>
        <li class="nav-item"  *ngIf="selectedProduct==='lease'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="3"
            routerLink="/lease-list"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-view-dashboard mr-2"></i> <span>Lease</span></a
          >
        </li>
        <li class="nav-item" *ngIf="(loggedInUserType === 'superadmin' || loggedInUserType === 'dowcadmin' || loggedInUserType === 'dowcsuperadmin') && selectedProduct==='lease'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="4"
            routerLink="/lease-analytics"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="fa fa-dashboard mr-2"></i> <span>Analytics</span></a
          >
        </li>
        <li class="nav-item"  *ngIf="selectedProduct==='keyfetch'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="3"
            routerLink="/keyprotection-list"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-view-dashboard mr-2"></i> <span>Key Protection</span></a
          >
        </li>
        <li class="nav-item" *ngIf="(loggedInUserType === 'superadmin' || loggedInUserType === 'dowcadmin' || loggedInUserType === 'dowcsuperadmin') && selectedProduct==='keyfetch'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="4"
            routerLink="/keyprotection-analytics"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="fa fa-dashboard mr-2"></i> <span>Analytics</span></a
          >
        </li>
        <li class="nav-item"  *ngIf="selectedProduct==='shield'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="3"
            routerLink="/360shield-list"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-view-dashboard mr-2"></i> <span>360 Shield</span></a
          >
        </li>
        <li class="nav-item" *ngIf="(loggedInUserType === 'superadmin' || loggedInUserType === 'dowcadmin' || loggedInUserType === 'dowcsuperadmin') && selectedProduct==='shield'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="4"
            routerLink="/360shield-analytics"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="fa fa-dashboard mr-2"></i> <span>Analytics</span></a
          >
        </li>
        <li class="nav-item"  *ngIf="selectedProduct==='debitcancellation'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="3"
            routerLink="/debitcancellation-list"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-view-dashboard mr-2"></i> <span>Debt Cancellation</span></a
          >
        </li>
        <li class="nav-item"  *ngIf="selectedProduct==='debitcancellation'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="3"
            routerLink="/debitcancellation-analytics"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
          <i class="fa fa-dashboard mr-2"></i> <span>Analytics</span></a
          >
        </li>
        <li class="nav-item"  *ngIf="selectedProduct==='reimburse'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="3"
            routerLink="/reimburse-list"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-view-dashboard mr-2"></i> <span>Reimbursement</span></a
          >
        </li>
        <li class="nav-item" *ngIf="(loggedInUserType === 'superadmin' || loggedInUserType === 'dowcsuperadmin') && selectedProduct==='reimburse'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="5"
            routerLink="/reimburse-report"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="fa fa-database mr-2"></i> <span>Reports</span></a
          >
        </li>
        <li class="nav-item"  *ngIf="selectedProduct==='theft'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="3"
            routerLink="/theft-list"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-view-dashboard mr-2"></i> <span>Theft</span></a
          >
        </li>

         <!--<li class="nav-item" *ngIf="loggedInUserType !== 'customer'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="2"
            routerLink="/user"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-account mr-2"></i> <span>Users</span></a
          >
        </li>
           <li class="nav-item" *ngIf="(user.audit && user.audit!==0) || loggedInUserType === 'dowcsuperadmin'">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="2"
            routerLink="/dealer"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-account-box mr-2"></i> <span>Dealers</span></a
          >
        </li>
       <li
          class="nav-item"
          *ngIf="user.audit && user.audit!==0">
          <a
            class="nav-link waves-effect waves-dark"
            tabindex="2"
            routerLink="/claim/reopened"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-delete-variant mr-2"></i>Re-Opened</a
          >
        </li>-->
      </ul>

      <!-- <ul class="navbar-nav breadcrumb-container mr-auto mt-md-0">
        <li class="breadcrumb-item" *ngIf="pageInfo" routerLink="{{pageInfo?.url}}">{{pageInfo?.title}}</li>
        <li class="breadcrumb-item" *ngIf="agentName.length && loggedInUserType === 'admin'">{{agentName}}</li>
        <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
          <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
            <a href='javascript:void(0)'>{{url.title}}</a>
          </li>
          <li class="breadcrumb-item active" *ngIf="last">{{url.title}}</li>
        </ng-template>
      </ul> -->

      <!-- =========================  -->
      <!-- Agent Filter -->
      <!-- =========================  -->

      <!-- =========================  -->

      <!-- ============================================================== -->
      <!-- User profile and search -->
      <!-- ============================================================== -->
      <ul class="navbar-nav my-lg-0 ml-auto">
        <!-- ============================================================== -->
        <!-- Search -->
        <!-- ============================================================== -->
        <!-- <li class="nav-item hidden-sm-down search-box" > <a class="nav-link hidden-sm-down text-white waves-effect waves-dark" href="javascript:void(0)"><i class="ti-search"></i></a>
                    <form class="app-search">
                        <input type="text" class="form-control" placeholder="Search & enter"> <a class="srh-btn"><i class="ti-close"></i></a> </form>
                </li> -->
        <!-- ============================================================== -->
        <!-- Comment -->
        <!-- ============================================================== -->
        <li
          class="nav-item dropdown d-flex flex-row-reverse"
          ngbDropdown
          placement="bottom-right"
        >
          <a
            ngbDropdownToggle
            class="d-none nav-link text-white text-white waves-effect waves-dark"
            href="javascript:void(0)"
          >
            <i class="mdi mdi-message"></i>
            <div class="notify">
              <span class="heartbit"></span>
              <span class="point"></span>
            </div>
          </a>
          <div
            class="dropdown-menu mailbox animated slideInDown primary-background"
            ngbDropdownMenu
          >
            <ul>
              <li>
                <div class="drop-title">Notifications</div>
              </li>
              <li>
                <div class="message-center" [perfectScrollbar]="config">
                  <!-- Message -->
                  <a href="#" *ngFor="let notification of notifications">
                    <div class="round {{ notification.round }}">
                      <i class="{{ notification.icon }}"></i>
                    </div>
                    <div class="mail-contnet">
                      <h5>{{ notification.title }}</h5>
                      <span class="mail-desc">{{ notification.subject }}</span>
                      <span class="time">{{ notification.time }}</span>
                    </div>
                  </a>
                </div>
              </li>
              <li>
                <a class="nav-link text-center" href="javascript:void(0);">
                  <strong>Check all notifications</strong>
                  <i class="fa fa-angle-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <!-- ============================================================== -->
        <!-- End Comment -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Messages -->
        <!-- ============================================================== -->
        <!-- <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
                    <a ngbDropdownToggle class="nav-link text-white waves-effect waves-dark" href="javascript:void(0)"> <i class="mdi mdi-email"></i>
                        <div class="notify"> <span class="heartbit"></span> <span class="point"></span> </div>
                    </a>
                    <div class="dropdown-menu mailbox animated slideInDown" ngbDropdownMenu>
                        <ul>
                            <li>
                                <div class="drop-title">You have 4 new messages</div>
                            </li>
                            <li>
                                <div class="message-center" [perfectScrollbar]="config">
                                    <a href="#" *ngFor="let mymessage of mymessages">
                                        <div class="user-img"> <img src="{{mymessage.useravatar}}" alt="user" class="img-circle" width="40">
                                            <span class="profile-status {{mymessage.status}} pull-right"></span>
                                        </div>
                                        <div class="mail-contnet">
                                            <h5>{{mymessage.from}}</h5>
                                            <span class="mail-desc">{{mymessage.subject}}</span>
                                            <span class="time">{{mymessage.time}}</span>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <a class="nav-link text-center" href="javascript:void(0);"> <strong>See all e-Mails</strong> <i class="fa fa-angle-right"></i> </a>
                            </li>
                        </ul>
                    </div>
                </li> -->
        <!-- ============================================================== -->
        <!-- End Messages -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Profile -->
        <!-- ============================================================== -->
        <!-- <li class="nav-item">
          <a
            class="nav-link f-bold text-primary"
            href="javascript:void(0)"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              src="assets/images/users/1.jpg"
              alt="user"
              class="profile-pic mr-2"
            />
            John Doe</a
          >
        </li> -->
        <!-- <li class="nav-item">
          <a
            class="nav-link f-bold text-danger"
            href="javascript:;"
            (click)="logout()"
            title="Logout"
          >
            <i class="fa fa-power-off"></i>
          </a>
        </li> -->
        <li class="nav-item dropdown primary-background" ngbDropdown placement="bottom-right">
          <a
            ngbDropdownToggle
            class="nav-link dropdown-toggle text-white waves-effect waves-dark"
            href="javascript:void(0)"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <!-- <img
              src="assets/images/users/1.jpg"
              alt="user"
              class="profile-pic"
            /> -->
            <b class="f-bold ml-2 user-name">{{ user.firstName }} {{ user.lastName }}</b>
            <i class="fa fa-user-circle fa-fw"></i>
          </a>
          <div class="dropdown-menu animated slideInDown primary-background" ngbDropdownMenu>
            <ul class="dropdown-user">
              <li>
                <div class="dw-user-box">
                  <!-- <div class="u-img">
                    <img src="assets/images/users/1.jpg" alt="user" />
                  </div> -->
                  <div class="u-text text-capitalize">
                    <!-- <h4 *ngIf="user">{{user.firstName}} {{user.lastName}}</h4> -->
                    <h4>{{ user.firstName }} {{ user.lastName }}</h4>
                  </div>
                </div>
              </li>
              <li role="separator" class="divider"></li>
              <li *ngIf="loggedInUserType !== 'customer' && loggedInUserType !== 'adminview'">
                <a [routerLink]="['/user']" [routerLinkActive]="['active']"
                  ><i class="mdi mdi-account"></i> Users</a
                >
              </li>
              <li  *ngIf="(user.audit && user.audit!==0) || loggedInUserType === 'dowcsuperadmin'">
                <a [routerLink]="['/dealer']" [routerLinkActive]="['active']"
                ><i class="mdi mdi-account-box"></i> Dealers</a
              >
              </li>
              <li  *ngIf="(user.audit && user.audit!==0) || loggedInUserType === 'dowcsuperadmin'">
                <a [routerLink]="['/dealergroup']" [routerLinkActive]="['active']"
                ><i class="mdi mdi-group"></i> Dealer Group</a
              >
              </li>
              <li 
              *ngIf="(user.audit && user.audit!==0) || loggedInUserType === 'dowcsuperadmin'">
                <a
                  [routerLink]="['/audit']" [routerLinkActive]="['active']"
                  ><i class="fa fa-suitcase"></i> Audit</a
                >
              </li>
              <li 
              *ngIf="(user.audit && user.audit!==0) || loggedInUserType === 'dowcsuperadmin'">
                <a
                  [routerLink]="['/config']" [routerLinkActive]="['active']"
                  ><i class="fa fa-desktop"></i> Config</a
                >
              </li>
              <hr />
              <li>
                <a [routerLink]="['/profile']" [routerLinkActive]="['active']"
                  ><i class="ti-user"></i> Profile</a
                >
              </li>
              <li>
                <a
                  [routerLink]="['/change-password']"
                  [routerLinkActive]="['active']"
                  ><i class="fa fa-key"></i> Change Password</a
                >
              </li>
              <li>
                <a href="javascript:;" (click)="logout()">
                  <i class="fa fa-power-off"></i> Logout</a
                >
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>

<aside class="mobile-menu-container">
  <nav>
    <ul class="navbar-nav siderbar-menus-container mr-auto mt-md-0">
      <li class="nav-item">
        <a
        *ngIf="loggedInUserType === 'dowcsuperadmin'"
          class="nav-link waves-effect waves-dark"
          tabindex="1"
          routerLink="/support"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <i class="fa fa-comment-o mr-2"></i> Support</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="2"
          routerLink="/products"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <i class="mdi mdi-grid mr-2"></i> Products</a
        >
      </li>
      <li class="nav-item" *ngIf="loggedInUserType !== 'customer' && selectedProduct==='gap'">
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="3"
          routerLink="/dashboard"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <i class="mdi mdi-view-dashboard mr-2"></i> GAP Claim</a
        >
      </li>
      <li class="nav-item" *ngIf="(loggedInUserType === 'superadmin' || loggedInUserType === 'dowcadmin' || loggedInUserType === 'dowcsuperadmin') && selectedProduct==='gap'">
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="4"
          routerLink="/analytics"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <i class="fa fa-dashboard mr-2"></i> Analytics</a
        >
      </li>
      <li class="nav-item" *ngIf="(loggedInUserType === 'superadmin' || loggedInUserType === 'dowcsuperadmin') && selectedProduct==='gap'">
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="5"
          routerLink="/report"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <i class="fa fa-database mr-2"></i> Reports</a
        >
      </li>
     
      <li
        class="nav-item"
        *ngIf="
          (loggedInUserType === 'customer' ||
          (loggedInUserType !== 'customer' && selectedCustomer !== 'null') ) && selectedProduct==='gap'
        "
      >
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="6"
          routerLink="/claim"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
         <i class="mdi mdi-account mr-2"></i>Manage Claims
        </a>
      </li>

      <li
        class="nav-item"
        *ngIf="selectedProduct==='lease'"
      >
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="3"
          routerLink="/lease-list"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
         <i class="mdi mdi-view-dashboard mr-2"></i>Lease
        </a>
      </li>
      <li class="nav-item" *ngIf="(loggedInUserType === 'superadmin' || loggedInUserType === 'dowcadmin' || loggedInUserType === 'dowcsuperadmin') && selectedProduct==='lease'">
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="4"
          routerLink="/lease-analytics"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <i class="fa fa-dashboard mr-2"></i> Analytics</a
        >
      </li>

      <li
        class="nav-item"
        *ngIf="selectedProduct==='keyfetch'"
      >
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="3"
          routerLink="/lease-list"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
         <i class="mdi mdi-view-dashboard mr-2"></i>Key Protection
        </a>
      </li>
      <li class="nav-item" *ngIf="(loggedInUserType === 'superadmin' || loggedInUserType === 'dowcadmin' || loggedInUserType === 'dowcsuperadmin') && selectedProduct==='keyfetch'">
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="4"
          routerLink="/lease-analytics"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <i class="fa fa-dashboard mr-2"></i> Analytics</a
        >
      </li>
      <li
        class="nav-item"
        *ngIf="selectedProduct==='shield'"
      >
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="3"
          routerLink="/360shield-list"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
         <i class="mdi mdi-view-dashboard mr-2"></i>360 Shield
        </a>
      </li>
      <li class="nav-item" *ngIf="(loggedInUserType === 'superadmin' || loggedInUserType === 'dowcadmin' || loggedInUserType === 'dowcsuperadmin') && selectedProduct==='shield'">
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="4"
          routerLink="/360shield-analytics"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
          <i class="fa fa-dashboard mr-2"></i> Analytics</a
        >
      </li>
      <li
        class="nav-item"
        *ngIf="selectedProduct==='debitcancellation'"
      >
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="3"
          routerLink="/debitcancellation-list"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
         <i class="mdi mdi-view-dashboard mr-2"></i>Debt Cancellation
        </a>
      </li>
      <li
        class="nav-item"
        *ngIf="selectedProduct==='debitcancellation'"
      >
        <a
          class="nav-link waves-effect waves-dark"
          tabindex="3"
          routerLink="/debitcancellation-analytics"
          routerLinkActive="active"
          href="javascript:void(0)"
        >
         <i class="fa fa-dashboard mr-2"></i>Analytics
        </a>
      </li>

      <hr />
      <li class="nav-item mb-3">
        <a aria-expanded="false" aria-haspopup="true" class="nav-link text-capitalize" href="javascript:void(0)">
          {{ user.firstName }} {{ user.lastName }}
      </a></li>
      <li 
      class="nav-item"
      *ngIf="loggedInUserType !== 'customer' && loggedInUserType !== 'adminview'">
        <a
          class="nav-link waves-effect waves-dark"
          [routerLink]="['/user']" [routerLinkActive]="['active']"
          ><i class="mdi mdi-account mr-2"></i> Users</a
        >
      </li>
      <li 
      class="nav-item"
      *ngIf="(user.audit && user.audit!==0) || loggedInUserType === 'dowcsuperadmin'">
        <a
          class="nav-link waves-effect waves-dark"
          [routerLink]="['/dealer']" [routerLinkActive]="['active']"
          ><i class="mdi mdi-account-box mr-2"></i> Dealers</a
        >
      </li>
      <li 
      class="nav-item"
      *ngIf="(user.audit && user.audit!==0) || loggedInUserType === 'dowcsuperadmin'">
        <a
          class="nav-link waves-effect waves-dark"
          [routerLink]="['/dealergroup']" [routerLinkActive]="['active']"
          ><i class="mdi mdi-group mr-2"></i> Dealers Group</a
        >
      </li>      
      <li 
      class="nav-item"
      *ngIf="(user.audit && user.audit!==0) || loggedInUserType === 'dowcsuperadmin'">
        <a
          class="nav-link waves-effect waves-dark"
          [routerLink]="['/audit']" [routerLinkActive]="['active']"
          ><i class="fa fa-suitcase mr-2"></i> Audit</a
        >
      </li>
      <li 
      class="nav-item"
      *ngIf="(user.audit && user.audit!==0) || loggedInUserType === 'dowcsuperadmin'">
        <a
          class="nav-link waves-effect waves-dark"
          [routerLink]="['/config']" [routerLinkActive]="['active']"
          ><i class="fa fa-desktop mr-2"></i> Config</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link waves-effect waves-dark"
          routerLink="/profile"
          routerLinkActive="active"
        >
         <i class="ti-user"></i> Profile</a
        >
      </li>
      <li class="nav-item">
        <a 
          class="nav-link waves-effect waves-dark"
          [routerLink]="['/change-password']"
          routerLinkActive="active"
          ><i class="fa fa-key mr-2"></i> Change Password</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-danger"
          href="javascript:;"
          (click)="logout()"
          title="Logout"
        >
         <i class="fa fa-power-off mr-2"></i>
          Logout
        </a>
      </li>
    </ul>
  </nav>
</aside>
