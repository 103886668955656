<h4 class="title-bg p-2">Overall</h4>
<div class="row">
    <div class="col-12">
        <div class="card-group">
            <!-- New -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-buffer text-new"></i>
                            </h1>
                            <h3 class="">{{allclaimStatus.total}}</h3>
                            <h6 class="card-subtitle">Total Claims</h6>
                        </div>
                    </div>
                </div>
            </div>
            <!-- In progress -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-wallet text-inprogress"></i>
                                <div class="float-right">{{allclaimStatusBar.inprogress| number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{allclaimStatus.inprogress}}</h3>
                            <h6 class="card-subtitle">In Progress</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="inprogress" [value]="allclaimStatusBar.inprogress">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
            <!-- In review -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-alert-circle text-inreview"></i>
                                <div class="float-right">{{allclaimStatusBar.inreview| number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{allclaimStatus.inreview}}</h3>
                            <h6 class="card-subtitle">In Review</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="inreview" [value]="allclaimStatusBar.inreview">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Completed -->
            <!-- <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-briefcase-check text-completed"></i>
                                <div class="float-right">{{allclaimStatusBar.completed| number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{allclaimStatus.completed}}</h3>
                            <h6 class="card-subtitle">Completed</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="completed" [value]="allclaimStatusBar.completed">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- Rejeted -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-thumb-down text-rejected"></i>
                                <div class="float-right">{{allclaimStatusBar.rejected| number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{allclaimStatus.rejected}}</h3>
                            <h6 class="card-subtitle">Rejected</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="rejected" [value]="allclaimStatusBar.rejected">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
             <!-- Denied -->
             <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-block-helper text-rejected"></i>
                                <div class="float-right">{{allclaimStatusBar.denied| number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{allclaimStatus.denied}}</h3>
                            <h6 class="card-subtitle">Denied</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="denied" [value]="allclaimStatusBar.denied">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Approved -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-thumb-up text-approved"></i>
                                <div class="float-right">{{allclaimStatusBar.approved| number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{allclaimStatus.approved}}</h3>
                            <h6 class="card-subtitle">Approved</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="approved" [value]="allclaimStatusBar.approved">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row pt-2">
    <div class="col-12">
        <div class="card">
            <div class="card-body pl-0 pr-0 pb-2 pt-1">
                <form [formGroup]="fiterForm">
                    <a class="cursor-pointer hidden-xl-up pl-2" 
                        (click)="utilService.toggleDivClass('filter-analytics','hidden-lg-down')">
                            <i class="fa fa-plus mr-2"></i><i class="fa fa-minus mr-2 d-none"></i>Filter
                        </a>
                    <div class="row hidden-lg-down" id="filter-analytics">
                        <div class="col-12 d-xl-flex align-items-start">
                            <div class="col-12 col-xl-3">
                                <div class="form-group mb-0 pos-rel">
                                    <label class="d-block">From</label>
                                    <input class="form-control" type="text" name="date_from" ngbDatepicker
                                        placeholder="MM/DD/YYYY" #date_from="ngbDatepicker" (click)="date_from.toggle()"
                                        formControlName="date_from" [readonly]="true"
                                        [minDate]="{ year: 1900, month: 1, day: 1 }" [maxDate]="{
                                            year: utilService.getCurrentYear,
                                            month: utilService.getCurrentMonth,
                                            day: utilService.getCurrentDay
                                          }" />
                                </div>
                            </div>
                            <div class="col-12 col-xl-3">
                                <div class="form-group mb-0 pos-rel">
                                    <label class="d-block">To</label>
                                    <input class="form-control" type="text" name="date_to" ngbDatepicker
                                        placeholder="MM/DD/YYYY" #date_to="ngbDatepicker" (click)="date_to.toggle()"
                                        formControlName="date_to" [readonly]="true"
                                        [minDate]="{ year: 1900, month: 1, day: 1 }" [maxDate]="{
                          year: utilService.getCurrentYear,
                          month: utilService.getCurrentMonth,
                          day: utilService.getCurrentDay
                        }" />
                                </div>
                            </div>
                            <div class="col-12 col-xl-6">
                                <div class="form-group mb-0"
                                *ngIf="loggedInUserType=='dowcadmin' || loggedInUserType=='dowcsuperadmin' || user.isGroupDealer">
                                    <label class="d-block">Dealer</label>
                                    <app-form-error-wrapper [control]="fiterForm.controls['dealer']"
                                        [controlName]="'Dealer'" [apiErrorType]="'dealer'">
                                        <ng-template #rt let-r="result" let-t="term">
                                            {{ r.name }}
                                        </ng-template>
                                        <input class="form-control" type="text" [ngbTypeahead]="dealers"
                                            [inputFormatter]="formatter" formControlName="dealer" [resultTemplate]="rt"
                                            autocomplete="off" placeholder="Search Dealer" />
                                        <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15 top-73"
                                            (click)="
                          utilService.removeDate(
                            fiterForm,
                            'dealer'
                          )
                        " placement="left" ngbTooltip="Remove" *ngIf="fiterForm.controls['dealer'].value">
                                            <i class="ti-close text-danger m-r-10"></i>
                                        </a>
                                    </app-form-error-wrapper>
                                </div>
                                <div class="form-group mb-0"
                                *ngIf="(loggedInUserType === 'admin' || loggedInUserType === 'superadmin') && !user.isGroupDealer">
                                    <label class="d-block">Dealer</label>
                                    <span class="d-block pt-1">{{dealername}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<h4 class="title-bg p-2" style="display: none;">During date selected</h4>
<div class="row">
    <div class="col-12">
        <div class="card-group">
            <!-- New -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-buffer text-new"></i>
                            </h1>
                            <!-- <h3 class="">{{claimStatus.total}}</h3> 
                            <h6 class="card-subtitle">Total Claims</h6> -->
                            <h3 class="">{{priodclaimStatus.total}}</h3>
                            <h6 class="card-subtitle">Total Claims (new)</h6>
                        </div>
                    </div>
                </div>
            </div>
            <!-- In progress -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-wallet text-inprogress"></i>
                                <div class="float-right">{{claimStatusBar.inprogress | number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{claimStatus.inprogress}}</h3>
                            <h6 class="card-subtitle">In Progress</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="inprogress" [value]="claimStatusBar.inprogress">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
            <!-- In review -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-alert-circle text-inreview"></i>
                                <div class="float-right">{{claimStatusBar.inreview | number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{claimStatus.inreview}}</h3>
                            <h6 class="card-subtitle">In Review</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="inreview" [value]="claimStatusBar.inreview">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Completed -->
            <!-- <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-briefcase-check text-completed"></i>
                                <div class="float-right">{{claimStatusBar.completed | number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{claimStatus.completed}}</h3>
                            <h6 class="card-subtitle">Completed</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="completed" [value]="claimStatusBar.completed">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- Rejeted -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-thumb-down text-rejected"></i>
                                <div class="float-right">{{claimStatusBar.rejected | number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{claimStatus.rejected}}</h3>
                            <h6 class="card-subtitle">Rejected</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="rejected" [value]="claimStatusBar.rejected">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Denied -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-block-helper text-rejected"></i>
                                <div class="float-right">{{claimStatusBar.denied | number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{claimStatus.denied}}</h3>
                            <h6 class="card-subtitle">Denied</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="denied" [value]="claimStatusBar.denied">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Approved -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-thumb-up text-approved"></i>
                                <div class="float-right">{{claimStatusBar.approved | number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{claimStatus.approved}}</h3>
                            <h6 class="card-subtitle">Approved</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="approved" [value]="claimStatusBar.approved">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<h4 class="title-bg p-2" style="display: none;">In this period</h4>
<div class="row" style="display: none;">
    <div class="col-12">
        <div class="card-group">
            <!-- New -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-buffer text-new"></i>
                            </h1>
                            <h3 class="">{{priodclaimStatus.total}}</h3>
                            <h6 class="card-subtitle">Total Claims (new)</h6>
                        </div>
                    </div>
                </div>
            </div>
            <!-- In progress -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-wallet text-inprogress"></i>
                                <div class="float-right">{{priodclaimStatusBar.inprogress | number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{priodclaimStatus.inprogress}}</h3>
                            <h6 class="card-subtitle">In Progress</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="inprogress" [value]="priodclaimStatusBar.inprogress">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
            <!-- In review -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-alert-circle text-inreview"></i>
                                <div class="float-right">{{priodclaimStatusBar.inreview | number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{priodclaimStatus.inreview}}</h3>
                            <h6 class="card-subtitle">In Review</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="inreview" [value]="priodclaimStatusBar.inreview">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Completed -->
            <!-- <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-briefcase-check text-completed"></i>
                                <div class="float-right">{{priodclaimStatusBar.completed | number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{priodclaimStatus.completed}}</h3>
                            <h6 class="card-subtitle">Completed</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="completed" [value]="priodclaimStatusBar.completed">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- Rejeted -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-thumb-down text-rejected"></i>
                                <div class="float-right">{{priodclaimStatusBar.rejected | number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{priodclaimStatus.rejected}}</h3>
                            <h6 class="card-subtitle">Rejected</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="rejected" [value]="priodclaimStatusBar.rejected">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Approved -->
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="m-b-0">
                                <i class="mdi mdi-thumb-up text-approved"></i>
                                <div class="float-right">{{priodclaimStatusBar.approved | number:'1.0-0'}}%</div>
                            </h1>
                            <h3 class="">{{priodclaimStatus.approved}}</h3>
                            <h6 class="card-subtitle">Approved</h6>
                        </div>
                        <div class="col-12">
                            <ngb-progressbar [showValue]="false" type="approved" [value]="priodclaimStatusBar.approved">
                            </ngb-progressbar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ============================================================== -->
<!-- chart and stats -->
<!-- ============================================================== -->
<div class="row">
  <!-- chart component -->
  <div class="col-lg-8">
      <div class="card">
          <div class="card-body">
              <div class="d-flex flex-wrap m-b-30">
                  <div>
                      <h4 class="card-title">Lease Excess Wear & Tear Claim</h4>
                      <h6 class="card-subtitle">From: {{fromdate| date: 'MM/dd/yyyy' }} to:
                          {{todate| date: 'MM/dd/yyyy' }}</h6>
                  </div>
               </div>
              <div style="min-height:350px;">
                  <!-- <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
                      [colors]="lineChartColors1" [legend]="true" [chartType]="'bar'"> </canvas> -->
                      <canvas baseChart [chartType]="'bar'" [datasets]="lineChartData" [labels]="lineChartLabels"
                      [options]="lineChartOptions" [legend]="true" [colors]="lineChartColors1">
                  </canvas>
              </div>
          </div>
      </div>

  </div>
  <!-- other chart component -->
  <div class="col-lg-4">
      <!--<div class="card card-inverse card-success">
          <div class="card-body">
              <div class="d-flex">
                  <div class="m-r-20 align-self-center">
                      <h1 class="text-white">
                          <i class="ti-pie-chart"></i>
                      </h1>
                  </div>
                  <div>
                      <h3 class="card-title">Claims Completed</h3>
                      <h6 class="card-subtitle">Jan 2020</h6>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6 align-self-center">
                      <h2 class="font-light text-white">{{totalCompleted}}</h2>
                  </div>
                  <div class="col- ml-auto p-r-20">
                      <canvas baseChart width="120px" height="120px" [data]="pieChartData" [labels]="pieChartLabels"
                          [chartType]="pieChartType" [legend]="lineChartLegend"
                          [colors]="[{backgroundColor: ['#1cadbf', '#1f5f67', '#ffffff'], borderColor:['#26dad2', '#26dad2', '#26dad2'] }]">
                      </canvas>
                  </div>
              </div>
          </div>
      </div>-->
      <div class="card">
          <div class="card-body">
              <h4 class="card-title">Claim Status</h4>
              <div style="min-height:410px;">
              <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                  [chartType]="pieChartType" [options]="pieChartOptions"
                  [colors]="[{backgroundColor: ['#A4BB39', '#6A6187', '#B75A50', '#B75A50', '#009B4C']}]">
              </canvas>
              </div>
          </div>
      </div>
  </div>
  <app-table-loader [isLoading]="isLoading"></app-table-loader>
</div>
