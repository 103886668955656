<div class="row agent-container">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        Key Protection Claims 
        <div class="card-actions">
          <button class="btn btn-white" routerLink="/keyprotection-create" *ngIf="loggedInUserType !== 'dowcview' && loggedInUserType !== 'adminview'">
            Create
          </button>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="card mb-0">
          <div class="card-body pl-0 pr-0 pb-0">
            <form [formGroup]="fiterForm">
              <a class="cursor-pointer hidden-xl-up pl-2" 
              (click)="utilService.toggleDivClass('filter-dashboard','hidden-lg-down')">
                <i class="fa fa-plus mr-2"></i><i class="fa fa-minus mr-2 d-none"></i>Filter
              </a>
              <div class="row hidden-lg-down" id="filter-dashboard">
                <div class="col-12 d-xl-flex align-items-start">
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Contract No</label>
                      <input
                        type="text"
                        name="addendumNumber"
                        class="form-control"
                        formControlName="addendumNumber"
                        addendumNumber
                        (keypress)="omit_special_char($event)"
                       />
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Claim No</label>
                      <input
                        type="text"
                        name="sequence"
                        class="form-control"
                        formControlName="sequence"
                        sequence
                        (keypress)="omit_special_char($event)"
                       />
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Name</label>
                      <input
                        type="text"
                        name="customerNameFilter"
                        class="form-control"
                        formControlName="customerName"
                        (keypress)="omit_special_char($event)"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Email</label>
                      <input
                        type="text"
                        name="customerEmailFilter"
                        class="form-control"
                        formControlName="email"
                        (keypress)="omit_special_Mailchar($event)"
                      />
                    </div>
                  </div>
                  <!-- <div class="col-12 col-xl-1">
                    <div class="form-group mb-0 pos-rel">
                      <label class="d-block small">From</label>
                      <input
                        class="form-control small-date"
                        type="text"
                        name="claimDateFilter"
                        ngbDatepicker
                        placeholder="From"
                        #date_from="ngbDatepicker"
                        (click)="date_from.toggle()"
                        formControlName="date_from"
                        [readonly]="true"
                        [minDate]="{ year: 1900, month: 1, day: 1 }"
                        [maxDate]="{
                          year: utilService.getCurrentYear,
                          month: utilService.getCurrentMonth,
                          day: utilService.getCurrentDay
                        }"
                      />
                      <a
                        class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel top-73"
                        (click)="
                          utilService.removeDate(
                            fiterForm,
                            'date_from'
                          )
                        "
                        placement="left"
                        ngbTooltip="Remove"
                        *ngIf="fiterForm.controls['date_from'].value"
                      >
                        <i class="ti-close text-danger m-r-10"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-12 col-xl-1">
                    <div class="form-group mb-0 pos-rel">
                      <label class="d-block small">To</label>
                      <input
                        class="form-control small-date"
                        type="text"
                        name="claimDateFilter"
                        ngbDatepicker
                        placeholder="To"
                        #date_to="ngbDatepicker"
                        (click)="date_to.toggle()"
                        formControlName="date_to"
                        [readonly]="true"
                        [minDate]="{ year: 1900, month: 1, day: 1 }"
                        [maxDate]="{
                          year: utilService.getCurrentYear,
                          month: utilService.getCurrentMonth,
                          day: utilService.getCurrentDay
                        }"
                      />
                      <a
                        class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel top-73"
                        (click)="
                          utilService.removeDate(
                            fiterForm,
                            'date_to'
                          )
                        "
                        placement="left"
                        ngbTooltip="Remove"
                        *ngIf="fiterForm.controls['date_to'].value"
                      >
                        <i class="ti-close text-danger m-r-10"></i>
                      </a>
                    </div>
                  </div> -->
                  <div
                    *ngIf="(loggedInUserType == 'superadmin' || loggedInUserType == 'dowcsuperadmin' ||  loggedInUserType == 'dowcadmin')"
                    class="col-12 col-xl-2"
                  >
                    <div class="form-group mb-0">
                      <label class="d-block small">Dealer</label>
                      <app-form-error-wrapper [control]="fiterForm.controls['dealer']" [controlName]="'Dealer'"
                      [apiErrorType]="'dealer'">
                      <ng-template #rt let-r="result" let-t="term">
                        {{ r.name }}
                      </ng-template>
                      <input class="form-control" type="text" [ngbTypeahead]="dealers" [inputFormatter]="formatter"
                        formControlName="dealer" [resultTemplate]="rt" autocomplete="off" placeholder="Search Dealer" />
                        <a class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer pos-abs datecancel-r15 top-73"
                        (click)="
                          utilService.removeDate(
                            fiterForm,
                            'dealer'
                          )
                        "
                        placement="left"
                        ngbTooltip="Remove"
                        *ngIf="fiterForm.controls['dealer'].value"
                      >
                        <i class="ti-close text-danger m-r-10"></i>
                      </a>
                    </app-form-error-wrapper>
                     
                     <!-- <select
                        class="form-control custom-select"
                        name="dealer"
                        formControlName="dealer"
                      >
                        <option value="">All</option>
                        <option
                          *ngFor="let dealer of dealersList"
                          [value]="dealer._id"
                          >{{ dealer.name }}</option
                        >
                      </select>-->
                    </div>
                  </div>
                  <div
                    class="col-12 col-xl-2">
                    <div class="form-group mb-0">
                      <label class="d-block small">Status</label>
                      <select
                        class="form-control custom-select"
                        name="filter-user"
                        formControlName="status"
                      >
                        <option value="">All</option>
                        <option value="in progress">In Progress</option>
                        <option value="in review">In Review</option>
                        <option value="denied">Denied</option>
                        <option value="rejected">Rejected</option>
                        <option value="approved">Approved</option>
                        <option value="voided">Voided</option>
                      </select>
                    </div>
                  </div>
                  <div class="none">
                    <input
                      type="hidden"
                      name="sortFieldFilter"
                      formControlName="sortFieldFilter"
                      #sortFieldFilter
                    />
                    <input
                      type="hidden"
                      name="sortByFilter"
                      formControlName="sortByFilter"
                      #sortByFilter
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div
          class="table table-responsive smart-table"
          *ngIf="claims && claims.length > 0"
        >
          <table class="w-100">
            <thead>
              <tr>
                <th>Contract No</th>
                <th class="pointer">
                  <div
                    [ngClass]="{
                      sort: sortBy === 'sequence',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }"
                    (click)="sort('sequence')"
                  >
                  Claim No
                  </div>
                </th>
                <th class="pointer">
                  <div
                    [ngClass]="{
                      sort: sortBy === 'customerName',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }"
                    (click)="sort('customerName')"
                  >
                    Name
                  </div>
                </th>
                <!-- <th>Phone No</th> -->
                <th>Email</th>
                <th class="pointer">
                  <div
                    [ngClass]="{
                      sort: sortBy === 'claimDate',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }"
                    (click)="sort('claimDate')"
                  >
                  Claim Date
                  </div>
                </th>
                <th class="pointer">
                  <div
                    [ngClass]="{
                      sort: sortBy === 'createdAt',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }"
                    (click)="sort('createdAt')"
                  >
                    Created
                  </div>
                </th>
                <th *ngIf="loggedInUserType === 'dowcadmin' || loggedInUserType === 'dowcsuperadmin'">Source</th>
                <th>Selling Dealer</th>
                <th class="pointer claim-status">
                  <div
                    [ngClass]="{
                      sort: sortBy === 'status',
                      asc: sortDir == 1,
                      desc: sortDir == -1
                    }"
                    (click)="sort('status')"
                  >
                    Status
                  </div>
                </th>
                <th class="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let claim of claims"
              >
                <td>{{ claim.contractNumber }}</td>
                <td><span *ngIf="claim.sequence">{{ claim.sequence}}</span></td>
                <td>{{ claim.customerName }}</td>
                <!-- <td>{{ claim.phoneNumber | phone }}</td> -->
                <td>{{ claim.email | lowercase }}</td>
                <td>{{ claim.claimDate | date: 'MM/dd/yyyy' }}</td>
                <td>{{ claim.createdAt | date: 'MM/dd/yyyy' }}</td>
                <td *ngIf="loggedInUserType === 'superadmin' || loggedInUserType === 'dowcsuperadmin'">
                  <span>{{(claim.domain && claim.domain.indexOf("assuredprovider") !=-1)?'APS':'DOWC'}}</span>
               </td>
                <td *ngIf="claim.dealer" class="list-dealer-td">
                  {{ claim?.dealer?.name }}
                </td>
                <td *ngIf="!claim.dealer"></td>
                <td>
                  <div class="d-flex align-items-center m-action">
                    <span
                      class="label label-warning"
                      [ngClass]="{
                        'bg-inprogress': claim.status === 'in progress',
                        'bg-inreview': claim.status === 'in review',
                        'bg-approved': claim.status === 'approved',
                        'bg-completed': claim.status === 'completed',
                        'bg-rejected': claim.status === 'rejected',
                        'bg-denied': claim.status === 'denied',
                        'bg-voided': claim.status === 'voided',
                        'mr-2':
                          claim.status === 'voided' ||
                          claim.status === 'rejected' ||
                          claim.status === 'in review' ||
                          claim.status === 'completed'
                      }"
                      >{{ claim.status | titlecase }}</span
                    >
                    <a
                      class="cursor-pointer f-20 l-h-10"
                      placement="top"
                      ngbTooltip="Deny"
                      (click)="openRejectPopup(claim,'denied')"
                      *ngIf="
                        (claim.status === 'completed' &&
                        ((loggedInUserType === 'superadmin' && checkAdmindealer(claim.dealer._id)) || loggedInUserType === 'dowcsuperadmin')) ||
                        (claim.status === 'in review' &&
                        ((loggedInUserType === 'superadmin' && checkAdmindealer(claim.dealer._id)) || loggedInUserType === 'dowcsuperadmin'))
                      "
                    >
                      <i class="icon-ban text-danger m-r-5"></i>
                    </a>
                    <a
                      class="cursor-pointer f-20 l-h-10"
                      placement="top"
                      ngbTooltip="Reject"
                      (click)="openRejectPopup(claim,'reject')"
                      *ngIf="
                        (claim.status === 'completed' &&
                        ((loggedInUserType === 'superadmin' && checkAdmindealer(claim.dealer._id)) || loggedInUserType === 'dowcsuperadmin')) ||
                        (claim.status === 'in review' &&
                        ((loggedInUserType === 'superadmin' && checkAdmindealer(claim.dealer._id)) || loggedInUserType === 'dowcsuperadmin'))
                      "
                    >
                      <i class="icon-close text-danger m-r-10"></i>
                    </a>
                    <a
                      class="cursor-pointer f-20 l-h-10"
                      ngbPopover="{{ claim.statusMessage }}"
                      triggers="mouseenter:mouseleave"
                      popoverTitle="Reject Reason"
                      *ngIf="claim.status === 'rejected' && claim.statusMessage"
                    >
                      <i class="mdi mdi-alert-octagon text-review m-r-10 "></i>
                    </a>
                    <a
                    class="cursor-pointer f-20 l-h-10"
                    ngbPopover="{{ claim.statusMessage }}"
                    triggers="mouseenter:mouseleave"
                    popoverTitle="Denied Reason"
                    *ngIf="claim.status === 'denied' && claim.statusMessage"
                  >
                    <i class="mdi mdi-alert-octagon text-review m-r-5 "></i>
                  </a>
                  <a
                      class="cursor-pointer f-20 l-h-10"
                      ngbPopover="{{ claim.statusMessage }}"
                      triggers="mouseenter:mouseleave"
                      popoverTitle="Void Reason"
                      *ngIf="claim.status === 'voided' && claim.statusMessage"
                    >
                      <i class="mdi mdi-alert-octagon text-review m-r-5 "></i>
                    </a>
                    <a
                      class="cursor-pointer f-20 l-h-10"
                      placement="top"
                      ngbTooltip="Accept"
                      (click)="openConfirmPopup(claim)"
                      *ngIf="
                        (claim.status === 'completed' || claim.status === 'in review' ) &&
                        ((loggedInUserType === 'superadmin' && claim?.dealer?._id === loggedInUserdealer) || loggedInUserType == 'dowcsuperadmin')
                      "
                    >
                      <i class="icon-check text-success m-r-10"></i>
                    </a>
                  </div>
                </td>
                <td class="text-right m-action">
                  
                  <a
                    class="ng2-smart-action ng2-smart-action-delete-delete cursor-pointer"
                    placement="top"
                    (click)="openRejectPopup(claim,'void')"
                    ngbTooltip="Void Claim"
                    *ngIf="
                      ((claim.status === 'in progress' && (1==(claim?.rejectedadminId?0:1 || (loggedInUserType === 'superadmin' && claim?.dealer?._id === loggedInUserdealer) || loggedInUserType == 'dowcsuperadmin')) )||
                      ((loggedInUserType === 'superadmin' && claim?.dealer?._id === loggedInUserdealer) || loggedInUserType == 'dowcsuperadmin')) &&
                        claim.status !== 'approved' && claim.status !== 'rejected' && loggedInUserType !== 'dowcview' && loggedInUserType !== 'adminview' && claim.status !== 'voided'"
                  >
                    <i class="fa fa-minus-circle void-icon text-danger m-r-10"></i>
                  </a>
                  <a
                  class="ng2-smart-action ng2-smart-action-edit-edit"
                  *ngIf=" (claim.status === 'in progress'  || claim.status === 'in review' || (claim.status === 'rejected' && claim?.rejectEdit)) && loggedInUserType !== 'dowcview' && loggedInUserType  && claim.status !== 'voided'"
                  routerLink="/keyprotection-edit/{{claim._id}}"
                  placement="top"
                  ngbTooltip="Update Claim"
                >
                  <i class="ti-pencil text-warning m-r-10"></i>
                </a>
                <a class="ng2-smart-action ng2-smart-action-edit-edit" routerLink="/keyprotectionview/{{ claim._id }}"
                    placement="top" ngbTooltip="Preview">
                    <i class="ti-eye text-info m-r-10"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <app-no-data-msg
          [message]="'No Claims Found'"
          *ngIf="claims && claims.length === 0 && !isLoading"
        >
        </app-no-data-msg>
        <app-table-loader [isLoading]="isLoading"></app-table-loader>
      </div>
      <div class="card-footer" *ngIf="claims && claims.length > 0">
        <div class="row">
          <div class="col-md">
            <span>{{
                              utilService.showPaginationInfo(pager.Totalrecords, pager.perPage, pager.currentPage)
                            }}</span>
          </div>
          <div class="col-sm">
            <form [formGroup]="fiterForm">
              <div class="form-group mb-0 pull-right">
                <small>Rows:</small>
                <select class="form-control custom-select rows-button" name="perPage" formControlName="perPage">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <input *ngIf="pager.TotalPages && pager.TotalPages>1" type="number" #goto name="goto"
                  class="form-control goto-input" placeholder="Page No" min="1" max="{{pager.TotalPages}}" />
                <span *ngIf="pager.TotalPages && pager.TotalPages>1" class="btn btn-link page-goto goto-btn" (click)="goPage()">
                  Go
                </span>
              </div>
            </form>
          </div>
          <div class="col-md" *ngIf="pager.TotalPages && pager.TotalPages>1">
            <div class="">
              <ul class="pagination pull-right">
                <li>
                  <a (click)="setPage(pager.currentPage - 1)"><i class="icon-arrow-left"></i></a>
                </li>
                <li *ngFor="let page of PageList" [ngClass]="{active:pager.currentPage === page.page}">
                  <a (click)="setPage(page.page)">{{page.label}}</a>
                </li>
                <li>
                  <a (click)="setPage(pager.currentPage + 1)"><i class="icon-arrow-right"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="help-container">
        <div class="help-section">
          <p class="help-text" (click)="openHelpPopup()">
            <i class="hellp-popup-icons"></i>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
